<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-icon
          @click="goBack"
          size="large"
          color="primary"
          slot="start"
          :icon="chevronBackOutline"
        ></ion-icon>
        <ion-label class="fw-600 fs-3" @click="goBack">{{ $t('payment_details') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <skeleton-payment-details v-if="loading && !isLoadingPaymentCreation"></skeleton-payment-details>
    <ion-content v-else>
      <div class="mx-3 mt-4 d-flex flex-column">
        <ion-label color="primary" class="fs-3 fw-500 pb-2">{{ labelHeader }}</ion-label>
      </div>
      <comp-calender-picker
        @dateTransaction="updateDateTransactionOrDeposit"
        @datePayment="updateDatePayment"
        @dateDeposit="updateDateTransactionOrDeposit"
      />
      <drop-zone class="drop-area" @files-dropped="onImageChange">
        <ion-input
          type="file"
          accept="image/*;capture=camera"
          @ionInput="onImageChange"
          ref="imageUpload"
          class="d-none"
        >
        </ion-input>
        <comp-payment-proof
          @triggerBottomSheet="setBottomSheetUpload(true)"
          :data="imageProof"
          @deleteImage="deleteImage"
          @onTapFullSizeImg="onTapFullSizeImg"
        />
      </drop-zone>
      <comp-input-remarks
        @inputRemarks="onChangeInputRemarks"
        @clearInput="clearInput"
        :inputValue="isBankTransferOrCashDeposit ? note : referenceCode"
        :isInputNote="isBankTransferOrCashDeposit"
      />
      <comp-summary-info
        v-if="isSalesUser"
        :selectedInvoices="selectedInvoices"
        :currencySymbol="currencySymbol"
        :customerCreditAllocation="customerCreditAmount"
        :totalInvoice="totalAmount"
        :grandTotal="grandTotal"
        :totalPaidAmount="totalPaidAmount"
      />
    </ion-content>
    <skeleton-bottom v-if="loading && !isLoadingPaymentCreation"></skeleton-bottom>
    <comp-bottom-content
      v-else
      @handlePayInvoices="createPayment"
      :totalPaidAmount="totalPaidAmount"
      :currencySymbol="currencySymbol"
      :disabledPay="disabledPay"
    />
    <ion-modal :is-open="isSummary" :backdropDismiss="false">
      <modal-payment-summary
        @backToAccount="backToAccount"
        @backToInvoice="backToInvoice"
        @backToHome="backToHome"
        :currencySymbol="currencySymbol"
        :paymentSummary="paymentSummary"
        :invoice="selectedInvoices"
        :isBankTransfer="isBankTransferOrCashDeposit"
        :paymentType="paymentType"
      />
    </ion-modal>
    <ion-modal
      :is-open="isBottomSheetUpload"
      :backdropDismiss="true"
      :initial-breakpoint="0.2"
      @didDismiss="setBottomSheetUpload(false)"
      class="bor-15"
    >
      <ion-content>
        <ion-input
          placeholder=""
          type="file"
          accept="image/*;capture=camera"
          @ionInput="onImageChange"
          ref="imageUpload"
          class="d-none"
        >
        </ion-input>
        <ion-list>
          <ion-item>
            <ion-label @click="triggerUpload" class="text-center" color="primary">{{
              $t('open_document')
            }}</ion-label>
          </ion-item>
          <ion-item v-if="isNative">
            <ion-label @click="triggerOpenCam" class="text-center" color="primary">{{
              $t('camera')
            }}</ion-label>
          </ion-item>
          <ion-button @click="setBottomSheetUpload(false)" fill="outline" expand="block" class="no-border">
            <ion-label class="text-capitalize fw-600" color="primary">{{ $t('cancel') }}</ion-label>
          </ion-button>
        </ion-list>
      </ion-content>
    </ion-modal>
    <ion-modal :is-open="isOpenFullImage" @didDismiss="setOpenFullImage(false)">
      <preview-image-collection :images="[imagePreview]" @closePage="setOpenFullImage(false)" />
    </ion-modal>
    <ion-toast
      :is-open="imgError"
      mode="ios"
      color="danger"
      :message="imgErrorMessage"
      :duration="2000"
      position="top"
      @didDismiss="imgError = false"
    >
    </ion-toast>
    <ion-toast
      :is-open="isInvalidDateFuture || isInvalidDatePast"
      mode="ios"
      color="danger"
      :message="
        isInvalidDateFuture
          ? `${$t('future_date_error')} ${todayDateError}!`
          : `${$t('past_date_error')} ${todayDateError}!`
      "
      :duration="2000"
      position="top"
      @didDismiss="(isInvalidDateFuture = false), (isInvalidDatePast = false)"
    >
    </ion-toast>
    <ion-loading
      :is-open="isLoadingPaymentCreation"
      :message="`${$t('please_wait')}...`"
      cssClass="my-custom-class"
      spinner="crescent"
    />
  </ion-page>
</template>
<script>
// packages
import { fileToBase64 } from '@/modules/sale/services/libs/image';
import { ACTIONS } from '@/modules/sale/store/payment/actions';
import { INVOICE_PAYMENT_METHOD, INVOICE_PAYMENT_TYPE, USER_TYPE_ID } from '@/modules/shared/constants/';
import { useAlert } from '@/modules/shared/utils';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import dayjs from 'dayjs';
import { chevronBackOutline, closeCircleOutline, helpCircleOutline } from 'ionicons/icons';
import { computed, defineAsyncComponent, defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { createNamespacedHelpers, useStore } from 'vuex';

// components
import DropZone from '@/components/DropZone';
import {
  CompPaymentProof,
  SkeletonBottom,
  SkeletonPaymentDetails
} from '@/modules/shared/components/invoices-payment';
import { useDateFormatter } from '@/usecases/global';
import { useBackButton } from '@ionic/vue';
import CompSummaryInfo from '../select-payment/components/CompSummaryInfo.vue';
import { CompBottomContent, CompCalenderPicker, CompInputRemarks, ModalPaymentSummary } from './components';

const { mapActions, mapGetters } = createNamespacedHelpers('sale/payment');

export default defineComponent({
  name: 'payment-details-sale',
  inject: ['$storage'],
  components: {
    CompCalenderPicker,
    CompInputRemarks,
    CompPaymentProof,
    CompBottomContent,
    ModalPaymentSummary,
    CompSummaryInfo,
    SkeletonPaymentDetails,
    SkeletonBottom,
    DropZone,
    PreviewImageCollection: defineAsyncComponent(() =>
      import('@/modules/shared/components/PreviewImageCollection.vue')
    )
  },
  setup() {
    const isNative = Capacitor.isNativePlatform();
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const { createAlert } = useAlert();
    const { formatDateMonthYear } = useDateFormatter();
    const isLoadingPaymentCreation = ref(false);
    const paymentType = +route.query['payment-type'];
    const isSummary = ref(false);
    const isBottomSheetUpload = ref(false);
    const setBottomSheetUpload = (state) => (isBottomSheetUpload.value = state);
    const setLoadingPaymentCreation = (state) => (isLoadingPaymentCreation.value = state);
    const isSalesUser = ref(false);
    const { BANK_TRANSFER, CHEQUE, CASH_DEPOSIT, CASH_PAYMENT_COLLECTION } = INVOICE_PAYMENT_TYPE;
    const isBankTransferOrCashDeposit = [BANK_TRANSFER, CASH_DEPOSIT].includes(paymentType)
      ? ref(true)
      : ref(false);
    const toggleInputCredit = ref(false);
    const imageUpload = ref(null);
    const imageProof = ref([]);
    const note = ref('');
    const referenceCode = ref('');
    const todayDate = dayjs().format('YYYY-MM-DD');
    const todayDateError = formatDateMonthYear(new Date());

    const isInvalidDateFuture = ref(false);
    const isInvalidDatePast = ref(false);
    const transactionDate = ref('');
    const paymentValueDate = ref('');
    const depositDate = ref('');
    const imgError = ref(false);
    const imgErrorMessage = ref('');
    const imagePreview = ref(null);
    const isOpenFullImage = ref(false);
    const labelOptions = {
      [BANK_TRANSFER]: t('bank_transfer_detail'),
      [CHEQUE]: t('cheque_detail'),
      [CASH_PAYMENT_COLLECTION]: t('cash_payment_collection_detail'),
      [CASH_DEPOSIT]: t('cash_deposit_detail')
    };
    const labelHeader = labelOptions[paymentType];
    const goBack = () => {
      router.back();
    };
    const navigateToRoute = (routePath) => {
      isSummary.value = false;
      store.dispatch(`sale/payment/${ACTIONS.REMOVE_SELECTED_PAYMENT_INVOICES}`);
      router.replace(routePath);
    };
    const backToInvoice = () => navigateToRoute('/sale/invoices/select-invoices');
    const backToAccount = () => navigateToRoute('/sale/account');
    const backToHome = () => navigateToRoute('/sale/main/home');
    const triggerUpload = async () => {
      const ionInputEl = imageUpload.value.$el;
      const inputEl = await ionInputEl.getInputElement();
      inputEl.click();
    };
    const onImageChange = async (e) => {
      const files = e.target ? e.target.querySelector('input').files : e;
      if (!files.length) return;
      if (!files[0].type.includes('image')) {
        imgError.value = true;
        imgErrorMessage.value = t('image_error_type');
        return;
      }
      if (files[0].size / 1024 > 5000) {
        imgError.value = true;
        imgErrorMessage.value = t('image_error_size');
        return;
      }
      const file = {
        imgName: files[0].name,
        imgSize: (files[0].size / 1024).toFixed(2) + ' KB',
        imgUrl: await fileToBase64(files[0]),
        imgBase64: await fileToBase64(files[0])
      };
      imageProof.value.push(file);
      setBottomSheetUpload(false);
    };
    const triggerOpenCam = async () => {
      await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri
      }).then(async (image) => {
        const fetchImgPath = await fetch(String(image.webPath));
        const blob = await fetchImgPath.blob();
        const imgBase64 = await fileToBase64(blob);
        const padding = imgBase64.endsWith('==') ? 2 : imgBase64.endsWith('=') ? 1 : 0;
        const base64Length = imgBase64.length;
        const sizeInBytes = (base64Length / 4) * 3 - padding;
        const sizeInKb = (sizeInBytes / 1024).toFixed(2);
        if (sizeInKb > 5000) {
          imgError.value = true;
          imgErrorMessage.value = t('image_error_size');
          return;
        }
        const file = {
          imgName: image.path.split('/').pop(),
          imgSize: sizeInKb + ' KB',
          imgUrl: await String(image.webPath),
          imgBase64
        };
        imageProof.value.push(file);
        setBottomSheetUpload(false);
      });
    };
    const deleteImage = (idx) => {
      imageProof.value.splice(idx, 1);
    };

    const onChangeInputRemarks = (value) => {
      note.value = value;
      referenceCode.value = value;
    };
    const clearInput = () => {
      if (isBankTransferOrCashDeposit.value) note.value = '';
      else referenceCode.value = '';
    };
    const resetLocalState = () => {
      isSummary.value = true;
      deleteImage();
      note.value = '';
      referenceCode.value = '';
    };

    const setOpenFullImage = (state) => (isOpenFullImage.value = state);
    const onTapFullSizeImg = (imgUrl) => {
      imagePreview.value = imgUrl;
      setOpenFullImage(true);
    };
    const isCreateTopUpPayment = computed(
      () => store.getters[`sale/payment/paramsTopUpCredit`]?.totalPaidAmount
    );

    const updateDateTransactionOrDeposit = (data) => {
      const { date, type } = data;
      const futureDate = dayjs().add(1, 'hours');
      const compareDate = dayjs(date).diff(futureDate);
      if (compareDate > 0) {
        isInvalidDateFuture.value = true;
        return;
      }
      if (type === 'DATE_TRANSACTION') {
        transactionDate.value = dayjs(date).format('YYYY-MM-DD');
      } else {
        depositDate.value = dayjs(date).format('YYYY-MM-DD');
      }
    };
    const updateDatePayment = (date) => {
      const today = dayjs().date();
      const isSameDate = dayjs(date).date() === today;
      const futureDate = dayjs().add(1, 'hours');
      const compareDate = dayjs(date).diff(futureDate);
      if (isSameDate) {
        isInvalidDatePast.value = false;
        paymentValueDate.value = dayjs(date).format('YYYY-MM-DD');
      } else {
        if (compareDate < 0 && !paymentType === BANK_TRANSFER) {
          isInvalidDatePast.value = true;
        } else {
          isInvalidDatePast.value = false;
          paymentValueDate.value = dayjs(date).format('YYYY-MM-DD');
        }
      }
    };
    useBackButton(10, () => {
      router.back();
    });
    return {
      isCreateTopUpPayment,
      todayDateError,
      labelHeader,
      isSummary,
      helpCircleOutline,
      chevronBackOutline,
      closeCircleOutline,
      toggleInputCredit,
      imageUpload,
      imageProof,
      note,
      referenceCode,
      paymentType,
      isBankTransferOrCashDeposit,
      isLoadingPaymentCreation,
      todayDate,
      isSalesUser,
      router,
      route,
      imgError,
      imgErrorMessage,
      INVOICE_PAYMENT_TYPE,
      INVOICE_PAYMENT_METHOD,
      isNative,
      isBottomSheetUpload,
      transactionDate,
      paymentValueDate,
      depositDate,
      isInvalidDateFuture,
      isInvalidDatePast,
      isOpenFullImage,
      imagePreview,
      paymentProof: ref([]),
      creditAllocationNotes: ref(null),
      isAdmin: ref(false),
      totalPaidAmount: ref(0),
      setLoadingPaymentCreation,
      updateDateTransactionOrDeposit,
      updateDatePayment,
      setBottomSheetUpload,
      onTapFullSizeImg,
      backToAccount,
      setOpenFullImage,
      createAlert,
      resetLocalState,
      onChangeInputRemarks,
      backToInvoice,
      backToHome,
      goBack,
      clearInput,
      deleteImage,
      triggerUpload,
      triggerOpenCam,
      onImageChange
    };
  },
  async ionViewDidEnter() {
    const { user_type_id } = await this.$storage.getUser();
    if (user_type_id === USER_TYPE_ID.ADMIN) this.isAdmin = true;
    this.totalPaidAmount = this.selectedPaymentInvoices?.totalPaidAmount;
    if (this.route.query?.isSalesUser) {
      this.isSalesUser = true;
    } else {
      this.isSalesUser = false;
    }
    if (INVOICE_PAYMENT_METHOD.DIGITAL.includes(this.paymentType)) {
      this.isSummary = true;
    }
  },
  computed: {
    ...mapGetters([
      'paymentSummary',
      'status',
      'error',
      'loading',
      'selectedPaymentInvoices',
      'paramsTopUpCredit'
    ]),
    selectedInvoices() {
      return this.selectedPaymentInvoices?.selectedInvoices;
    },
    disabledPay() {
      if (this.isAdmin && !this.isBankTransferOrCashDeposit && this.referenceCode.length === 0) {
        return true;
      } else if (!this.isAdmin && this.imageProof.length === 0) {
        return true;
      } else if (!this.isAdmin && !this.isBankTransferOrCashDeposit && this.referenceCode.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    grandTotal() {
      return this.totalAmount - this.customerCreditAmount;
    },
    totalAmount() {
      return this.selectedPaymentInvoices?.totalInvoiceAmount;
    },
    currencySymbol() {
      return this.selectedPaymentInvoices?.selectedInvoices[0]?.currencySymbol;
    },
    customerCreditAmount() {
      return this.selectedPaymentInvoices?.customerCreditAllocation || 0;
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.CREATE_BANK_PAYMENT,
      ACTIONS.CREATE_CASH_CHEQUE_PAYMENT,
      ACTIONS.CREATE_TOP_UP_CREDIT,
      ACTIONS.SET_PARAMS_TOP_UP_CREDIT
    ]),
    async createPayment() {
      this.setLoadingPaymentCreation(true);
      if (this.isCreateTopUpPayment) {
        await this.createPaymentTopUpCredit();
      } else {
        if ([INVOICE_PAYMENT_TYPE.BANK_TRANSFER].includes(this.paymentType)) {
          await this.createPaymentInvoicesBankTransfer();
        } else {
          await this.createPaymentInvoicesChequeCash();
        }
      }
      this.setLoadingPaymentCreation(false);
      if (this.error) {
        this.createAlert(
          this.$t('payment_creation_failed'),
          this.error?.message ?? '',
          this.backToInvoice,
          this.$t('OK')
        );
      } else {
        this.createAlert(
          this.$t('payment_pending'),
          this.$t('payment_pending_info'),
          this.resetLocalState,
          this.$t('OK')
        );
      }
    },

    getInvoiceIDsAndDebitNoteIDs() {
      let invoiceIDs = [];
      let debitNoteIDs = [];
      for (const invoice of this.selectedInvoices) {
        if (invoice.debitNoteId) {
          debitNoteIDs.push(invoice.debitNoteId);
        } else {
          invoiceIDs.push(invoice.invoiceId);
        }
      }

      return { invoiceIDs, debitNoteIDs };
    },

    async createPaymentInvoicesBankTransfer() {
      const paymentProof = this.imageProof.map((proof) => proof.imgBase64) || [];
      const { invoiceIDs, debitNoteIDs } = this.getInvoiceIDsAndDebitNoteIDs();
      const paramsCreateBankPayment = {
        invoiceIDs,
        debitNoteIDs,
        transactionDate: this.transactionDate || this.todayDate,
        valueDate: this.paymentValueDate || this.todayDate,
        paymentTypeId: INVOICE_PAYMENT_TYPE.BANK_TRANSFER,
        paymentProof,
        referenceCode: ``,
        notes: this.note,
        totalPaidAmount: Number(this.totalPaidAmount.toFixed(2)),
        balanceAllocationAmount: this.customerCreditAmount
      };
      await this[ACTIONS.CREATE_BANK_PAYMENT]({ paramsCreateBankPayment });
    },

    async createPaymentInvoicesChequeCash() {
      const paymentProof = this.imageProof.map((proof) => proof.imgBase64) || [];
      const { invoiceIDs, debitNoteIDs } = this.getInvoiceIDsAndDebitNoteIDs();
      const paramsCreateCashChequePayment = {
        invoiceIDs,
        debitNoteIDs,
        depositDate: this.depositDate || this.todayDate,
        paymentTypeId: this.paymentType,
        paymentProof,
        referenceCode: [INVOICE_PAYMENT_TYPE.CASH_DEPOSIT].includes(this.paymentType)
          ? ``
          : this.referenceCode,
        notes: [INVOICE_PAYMENT_TYPE.CASH_DEPOSIT].includes(this.paymentType) ? this.note : '',
        totalPaidAmount: Number(this.totalPaidAmount.toFixed(2)),
        balanceAllocationAmount: this.customerCreditAmount
      };
      await this[ACTIONS.CREATE_CASH_CHEQUE_PAYMENT]({ paramsCreateCashChequePayment });
    },

    async createPaymentTopUpCredit() {
      const { CHEQUE, CASH_PAYMENT_COLLECTION, BANK_TRANSFER, CASH_DEPOSIT } = INVOICE_PAYMENT_TYPE;
      const paymentProof = this.imageProof.map((proof) => proof.imgBase64) || [];
      const referenceNumber = [BANK_TRANSFER, CASH_DEPOSIT].includes(this.paymentType)
        ? ``
        : this.referenceCode;
      const notes = [CHEQUE, CASH_PAYMENT_COLLECTION].includes(this.paymentType) ? `` : this.note;
      await this[ACTIONS.SET_PARAMS_TOP_UP_CREDIT]({
        paramsTopUpCredit: {
          ...this.paramsTopUpCredit,
          referenceNumber,
          notes,
          transactionDate: this.transactionDate || this.todayDate,
          valueDate: this.paymentValueDate || this.todayDate
        }
      });
      await this[ACTIONS.CREATE_TOP_UP_CREDIT]({ paymentProof });
    }
  }
});
</script>
<style lang="scss" scoped>
.f-price {
  font-weight: 700;
  font-size: 22px;
}
.bor-15 {
  --border-radius: 15px;
}
.no-border {
  --border-color: none;
  --border-width: 0px !important;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
